<!-- 设置套餐 -->
<template>
  <el-dialog
      width="40%"
      top="5vh"
      :append-to-body="true"
      :visible.sync="dialogTableVisible"
      title="设置套餐"
      destroy-on-close
      :close-on-click-modal="false"
      @close="close"
  >
    <el-form ref="form" :model="dialogItem" label-width="120px" :rules="rules">
      <el-form-item label="套餐名称" prop="name">
        <el-input v-model="dialogItem.name"></el-input>
      </el-form-item>
      <el-form-item label="套餐类型">
        <el-radio-group v-model="dialogItem.limitState" size="medium">
          <el-radio label="1">限量套餐</el-radio>
          <el-radio label="2">不限量套餐</el-radio>
        </el-radio-group>
      </el-form-item>

      <el-form-item label="固定流量" prop="traffic">
        <el-input v-model="dialogItem.traffic">
          <template slot="append">月</template>
        </el-input>
      </el-form-item>

      <el-form-item label="收费" prop="chargeFee">
        <el-input v-model="dialogItem.chargeFee">
          <template slot="append">元 / 月</template>
        </el-input>
      </el-form-item>

      <template v-if="dialogItem.limitState === '1'">
        <el-form-item label="预警阈值">
          <el-input v-model="dialogItem.warnThreshold"></el-input>
        </el-form-item>
      </template>

      <template v-if="dialogItem.limitState === '2'">
        <div
            v-for="(item, index) in dialogItem.comboDetailList"
            :key="index"
            class="mb-4"
        >
          <div class="font-bold flex justify-between align-items-center">
            收费阶梯 {{ index + 1 }}
            <el-button type="text" size="small" @click="removeCombo(index)"
            >移除该阶梯
            </el-button>
          </div>
          <el-form-item label="用量超过">
            <el-input v-model="item.useSurpass">
              <template slot="append">MB</template>
            </el-input>
          </el-form-item>
          <el-form-item label="每用量">
            <el-input v-model="item.traffic">
              <template slot="append">MB</template>
            </el-input>
          </el-form-item>
          <el-form-item label="收费">
            <el-input v-model="item.chargeFee">
              <template slot="append">元</template>
            </el-input>
          </el-form-item>
        </div>
      </template>
    </el-form>
    <div
        slot="footer"
        class="dialog-footer flex flex-row align-items-center justify-between"
    >
      <el-button
          :disabled="dialogItem.limitState !== '2'"
          size="small"
          type="warning"
          class="float-left"
          @click="pushComboDetailList"
      >新增收费阶梯
      </el-button>
      <div>
        <el-button class="justify-self-end" @click="close">取消</el-button>
        <el-button class="justify-self-end" type="primary" @click="submit"
        >确定
        </el-button>
      </div>
    </div>
  </el-dialog>
</template>
<style scoped lang="less" rel="stylesheet/less"></style>
<script type="text/ecmascript-6">
export default {
  data () {
    return {
      dialogTableVisible: false,
      dialogItem: {
        name: '',
        limitState: '1',
        traffic: '',
        chargeFee: '',
        warnThreshold: 0,
        content: '',
        comboDetailList: []
      },
      rules: {
        name: [
          {
            required: true,
            message: '请输入套餐名称',
            trigger: 'blur'
          }
        ],
        traffic: [
          {
            required: true,
            message: '请输入固定流量',
            trigger: 'blur'
          }
        ],
        chargeFee: [
          {
            required: true,
            message: '请输入收费价格',
            trigger: 'blur'
          }
        ]
      }
    }
  },
  methods: {
    close () {
      this.dialogTableVisible = false
      this.dialogItem = {
        name: '',
        limitState: '1',
        traffic: '',
        chargeFee: '',
        warnThreshold: 0,
        content: '',
        comboDetailList: []
      }
    },
    show (item) {
      if (item) {
        this.selectItem(item)
      }
      this.dialogTableVisible = true
    },
    selectItem (item) {
      this.$api.select('sysCombo', item.id).then(res => {
        this.dialogItem = res.data
        this.dialogItem.limitState = (res.data.limitState).toString()
      })
    },
    submit () {
      this.$refs.form.validate((valid) => {
        if (valid) {
          if (this.dialogItem.limitState === '1') {
            this.dialogItem.comboDetailList = []
          } else if (this.dialogItem.limitState === '2') {
            if (this.dialogItem.comboDetailList.length === 0) {
              this.$message.error('请设置不限量套餐的收费阶梯')
              return false
            }

            let flag = false
            for (let i = 0; i < this.dialogItem.comboDetailList.length; i++) {
              const item = this.dialogItem.comboDetailList[i]
              if (item.useSurpass !== '' && item.traffic !== '' && item.chargeFee !== '') {
                flag = true
                break
              }
            }
            if (!flag) {
              this.$message.error('请检查收费阶梯价格内容')
              return false
            }
          }
          this.$api.save('sysCombo', this.dialogItem).then(() => {
            this.$emit('on-submit')
            this.close()
          })
        }
      })
    },
    pushComboDetailList () {
      this.dialogItem.comboDetailList.push({
        useSurpass: '',
        traffic: '',
        chargeFee: ''
      })
    },
    removeCombo (index) {
      this.dialogItem.comboDetailList.splice(index, 1)
    }
  },
  mounted () {
  }
}
</script>
