<!--  -->
<template>
  <div>
    <blockTitle title="套餐"></blockTitle>
    <el-card shadow="never">
      <div class="card-content">
        <AppTable
            ref="AppTable"
            controller="sysCombo"
            :tableColumns="tableColumns"
        >
          <template #left-operator>
            <el-button @click="addSimPackageDialog" size="small" type="primary"
            >新建套餐
            </el-button>
          </template>
          <template #limitState="{ row }">
            {{ row.limitState === 1 ? '限量套餐' : '不限量套餐' }}
          </template>
          <template #operatorBox="{ row }">
            <el-button @click="editItem(row)" type="default" size="mini"
            >编辑
            </el-button>
            <el-button @click="deleteItem(row)" type="danger" size="mini"
            >删除
            </el-button>
          </template>
        </AppTable>
      </div>
    </el-card>
    <addSimPackageDialog
        @on-submit="reGetData"
        ref="addSimPackageDialog"
    ></addSimPackageDialog>
  </div>
</template>
<style scoped lang="less" rel="stylesheet/less">
.card-content {
  height: calc(100vh - 180px);
}
</style>
<script type="text/ecmascript-6">
import addSimPackageDialog from 'components/sim/addSimPackageDialog'

export default {
  data () {
    return {
      tableColumns: [
        {
          label: '套餐名称',
          prop: 'name',
          width: '400px'
        },
        {
          label: '套餐类型',
          type: 'customSlot',
          slotName: 'limitState',
          width: '200px'
        },
        {
          label: '套餐内容',
          prop: 'content'
        },
        {
          label: '#',
          type: 'customSlot',
          slotName: 'operatorBox',
          width: '200px'
        }
      ]
    }
  },
  components: {
    addSimPackageDialog
  },
  methods: {
    addSimPackageDialog () {
      this.$refs.addSimPackageDialog.show()
    },
    deleteItem (item) {
      this.$api.delete('sysCombo', item.id).then(() => {
        this.$refs.AppTable.reGetData()
      })
    },
    editItem (item) {
      this.$refs.addSimPackageDialog.show(item)
    },
    reGetData () {
      this.$refs.AppTable.reGetData()
    }
  },
  mounted () {
  }
}
</script>
